import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "slug": "stats",
  "title": "Puntualità",
  "pretitle": "",
  "image": "../images/clock.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Ritiriamo la tua merce e lei è a destinazione entro 48 ore.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      